import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import DownloadMaterial from '../components/DownloadMaterial'
import Footer from '../components/Footer'
import { globalStyles } from '../components/layout.styles'

import {
  header,
  bgHeader,
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Aulão Brasil - Os melhores professores, das melhores escolas do Brasil." />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bgHeader}>
        <h1>Aulões Anteriores</h1>
      </div>
    </div>
    <DownloadMaterial />
    <div style={{ marginTop: 80 }} />
    <Footer />
  </Fragment>
)

export default StudentsPage
